import React from 'react'
import PropTypes from 'prop-types'

// Components
import PageLayout from '../components/Layout/PageLayout'

const Privacy = ({ location }) => {
  return (
    <PageLayout location={location} title="Privacy Policy" pageTitle="Privacy Policy" is404={true}>
      Bacon ipsum dolor amet chislic brisket ribeye turkey filet mignon. Beef jowl tongue
      tenderloin. Ground round rump brisket strip steak short ribs fatback beef ribs prosciutto
      porchetta flank. Cupim pork belly venison, kielbasa kevin salami jowl sirloin pork chop corned
      beef shankle. Bresaola pancetta t-bone shoulder. Sausage meatball doner ball tip short loin
      sirloin pastrami bacon pig picanha tail chicken leberkas buffalo. Ground round capicola
      shoulder, prosciutto pork belly bacon pork chop biltong fatback sirloin leberkas kielbasa
      drumstick shankle. Pork loin corned beef ball tip pork belly pastrami venison, turkey boudin
      salami ham prosciutto cow ground round swine. Short ribs beef ribs meatball corned beef
      drumstick jowl. Kielbasa pork andouille beef ribs tenderloin. Rump corned beef bresaola cow
      salami porchetta. Beef pork belly filet mignon, shank spare ribs sirloin flank pancetta ground
      round. Drumstick jowl short loin pork biltong, chislic tail salami kielbasa ball tip filet
      mignon capicola hamburger ground round. Ground round rump short loin leberkas picanha sirloin
      salami t-bone. Bacon meatloaf sausage alcatra andouille strip steak sirloin boudin picanha
      pork belly landjaeger jerky. Andouille venison fatback cow. Porchetta shoulder sirloin
      picanha, corned beef leberkas biltong tail bresaola buffalo. Ribeye shoulder corned beef
      capicola ham t-bone. Tri-tip cow pork chop, ham hock hamburger doner beef porchetta. Filet
      mignon bresaola venison, cow tenderloin ham chicken pork alcatra jerky picanha. Spare ribs
      pork loin tail shoulder meatball, cow short ribs pancetta ham hock drumstick. Boudin swine
      tri-tip sausage jowl kielbasa strip steak short loin fatback hamburger porchetta pork shoulder
      ham. Bresaola short ribs jowl, salami sausage venison meatball pancetta filet mignon
      landjaeger chicken tail frankfurter drumstick meatloaf. Pork loin chicken buffalo salami chuck
      tri-tip pastrami turkey t-bone ground round short ribs. Shoulder turkey filet mignon,
      landjaeger buffalo picanha spare ribs chislic. Salami turkey shoulder pig sirloin ball tip
      cupim jerky corned beef fatback chicken. Capicola drumstick shoulder, landjaeger beef pastrami
      bresaola. Does your lorem ipsum text long for something a little meatier? Give our generator a
      try… it’s tasty!
    </PageLayout>
  )
}

Privacy.propTypes = {
  location: PropTypes.object.isRequired,
}

export default Privacy
